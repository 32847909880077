<template>
  <div class="base-banner__highlight xl:my-16 my-4">
    <div
      class="grid grid-cols-1 lg:grid-cols-6 gap-4 grid-flow-row-dense"
      :class="orientation === 'left' && 'text-right'"
    >
      <div :class="orientation === 'right' ? 'col-span-2' : 'lg:col-start-5 lg:col-end-7 col-span-4'">
        <BaseTag v-if="!!featureText" :variant="featureVariant">
          {{ featureText }}
        </BaseTag>
        <div
          class="md:py-4 flex items-start gap-2"
          :class="orientation === 'left' ? 'justify-end' : 'justify-start'"
        >
          <BaseTitle
            level="h3"
            :class="orientation === 'left' && 'w-full'"
          >
            {{ title }}
            <EcoLabel
              v-if="!!ecoLabel"
              :label-type="(ecoLabel as EcoLabels)"
            />
          </BaseTitle>
        </div>
        <div class="base-banner__highlight-info">
          <BaseBannerHighlightPrice
            :price="price"
            :rent-period="rentPeriod"
            currency-symbol="€"
            :promotion-price="promotionPrice"
          />
          <div class="text-xl">
            <BaseCarPerks
              as="p"
              class="text-brand-black/50"
              :perks="perks"
            />
          </div>
          <div class="lg:block hidden">
            <BaseButtonLink
              class="mt-10 z-10"
              :to="ctaLink"
              @click="onClickCta(ctaLink)"
            >
              {{ ctaText }}
            </BaseButtonLink>
          </div>
        </div>
      </div>
      <figure
        class="lg:-mt-32 py-8 justify-end flex overflow-x-hidden"
        :class="[
          orientation === 'right' ? 'col-span-4 lg:pl-8' : 'col-start-1 col-end-5 col-span-4 scale-x-[-1] lg:pl-8'
        ]"
      >
        <ClientOnly>
          <BaseImage
            v-if="imageSrc"
            :src="imageSrc || ''"
            :alt="imageAlt"
            loading="lazy"
            class="h-auto w-full aspect-video max-w-[800px] object-contain"
            data-aos="fade-left"
            data-aos-duration="1500"
          />
        </ClientOnly>
      </figure>
    </div>
    <div class="flex justify-center lg:hidden">
      <BaseButtonLink
        class="mt-10 z-10"
        :to="ctaLink"
        @click="onClickCta(ctaLink)"
      >
        {{ ctaText }}
      </BaseButtonLink>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useGTM } from '~/composables';
import { type EcoLabels, type RentPeriod, type UiVariant } from '~/types';
import BaseCarPerks from '~/components/BaseCarPerks.vue';
import BaseImage from '~/components/BaseImage.vue';
import BaseButtonLink from '~/components/BaseButtonLink.vue';
import BaseTag from '~/components/BaseTag.vue';
import BaseTitle from '~/components/BaseTitle.vue';
import EcoLabel from '~/components/eco-labels/EcoLabel.vue';
import BaseBannerHighlightPrice from '~/components/BaseBannerHighlightPrice.vue';
import { TRACKING_EVENTS } from '~/lib/constants';

interface Props {
  orientation?: 'left' | 'right'
  title: string
  price: string | number
  promotionPrice?: string | number
  currencySymbol?: string
  rentPeriod?: RentPeriod
  perks: string[]
  featureText?: string
  featureVariant?: UiVariant
  ecoLabel?: EcoLabels
  tagVariant?: UiVariant
  ctaText: string
  ctaLink: string
  imageSrc: string
  imageAlt?: string
}

withDefaults(defineProps<Props>(), {
  orientation: 'left',
  rentPeriod: 'mes',
  promotionPrice: undefined,
  currencySymbol: '€',
  featureText: undefined,
  featureVariant: 'secondary',
  tagText: undefined,
  tagVariant: 'secondary',
  imageAlt: '',
  ecoLabel: undefined,
});

const { triggerEvent } = useGTM();
function onClickCta (url: string) {
  triggerEvent(TRACKING_EVENTS.CLICK_POPULAR_VEHICLE, { url });
}
</script>
