<template>
  <div class="base-banner__price">
    <div class="sr-only">
      price:
    </div>
    <div v-if="hasPromo" class="is-promotion">
      <span class="text-base">Desde </span>
      <span :class="hasPromo && 'line-through'">
        <span itemprop="price">
          {{ price }}
        </span>
        <span itemprop="priceCurrency">{{ currencySymbol }}</span>
        <template v-if="!!rentPeriod || rentPeriod !== 'none'">
          /{{ rentPeriod }}
        </template>
      </span>
    </div>

    <div class="text-2xl md:text-4xl text-brand-primary">
      <span v-if="!hasPromo" class="text-lg">Desde </span>
      <span itemprop="price">
        {{ hasPromo ? promotionPrice : price }}
      </span>
      <span itemprop="priceCurrency">{{ currencySymbol }}</span>
      <template v-if="!!rentPeriod || rentPeriod !== 'none'">
        /{{ rentPeriod }}
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
const props = defineProps<{
  price: number | string
  promotionPrice?: number | string
  currencySymbol: string
  rentPeriod: string
}>();

const hasPromo = computed(() => !!props.promotionPrice);
</script>

<style scoped>
.is-promotion {
  @apply text-xl md:text-2xl text-brand-black/50;
}
</style>
