<template>
  <BaseSection>
    <BaseBannerHighlight
      :title="data.title"
      :price="data.price"
      :promotion-price="data.promotion_price"
      :orientation="data.style"
      :feature-text="data.label.label_text"
      :feature-variant="data.label.label_variant"
      :eco-label="data.eco_label"
      :image-src="data?.image?.original.src"
      :image-alt="data?.image?.original.alt"
      :cta-text="data.cta_button.button_text"
      :cta-link="data.cta_button.button_link"
      :perks="computedPerks"
    />
  </BaseSection>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import BaseBannerHighlight from '../BaseBannerHighlight.vue';
import BaseSection from '../BaseSection.vue';

const props = defineProps({
  data: {
    required: true,
    type: Object,
  },
});

const computedPerks = computed(() => props.data?.perks.split(', '));
</script>
